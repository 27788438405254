.keyWalletButton {
  background-color: #c1d45d;
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 18px;
  line-height: 24px;
  border-radius: 12px;
  position: relative;
  border: none;
  height: 50px;
  color: #fff;
  cursor: pointer;
  padding: 0 24px 0 56px;
  margin-right: 24px;

  > img {
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }

  @media (max-width: 991px) {
    font-size: 14px;
    height: 34px;
    padding: 0 8px 0 32px;
    margin-right: 14px;

    > img {
      width: 16px;
      height: 16px;
      left: 8px;
    }
  }
}

.chainWrapper {
  &:hover {
    position: relative;
    z-index: 2;
    > ul {
      position: absolute;
      top: 35px;
      display: block;
    }
  }

  > ul {
    display: none;
  }
}

.BSCButton {
  background-color: #f3ba2f1a;
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 18px;
  line-height: 24px;
  border-radius: 12px;
  position: relative;
  border: none;
  height: 50px;
  color: #f3ba2f;
  cursor: pointer;
  padding: 0 20px 0 52px;
  margin-right: 24px;
  display: flex;
  align-items: center;

  span {
    display: block;
  }

  & > img {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
  }

  @media (max-width: 991px) {
    height: 34px;
    width: 40px;
    padding: 5px 8px;
    margin-right: 14px;

    span {
      display: none;
    }

    & > img {
      left: 8px;
    }
  }
}
