.chains {
  background-color: white;
  box-shadow: 0 0 5px gray;
  border-radius: 10px;
  padding: 10px 0;
}
.chain {
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  button {
    display: flex;
    align-items: center;
    text-align: left;
    min-height: 50px;
    width: 100%;
    background-color: unset;
    border: unset;
    padding-right: 20px;

    span {
      white-space: nowrap;
    }

    &:hover {
      background-color: rgb(162, 219, 180);
    }
  }
}
