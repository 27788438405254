.wrapperPartners {
  margin-bottom: 50px;
  padding: 0 10px;
  @media screen and (max-width: 700px) {
    margin-bottom: 30px;
  }
  .partners {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .slideWrapper {
    width: 300px;
    @media screen and (max-width: 700px) {
      width: 200px;
    }
    img {
      width: 300px;
      object-fit: cover;
      @media screen and (max-width: 700px) {
        width: 200px;
      }
    }
  }
}
