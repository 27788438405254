.wrapperAcademy {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 150px auto;
  @media (max-width: 991px) {
    margin: 70px auto;
  }
  & > h2 {
    margin-bottom: 40px;
  }
  & > img {
    margin: 10px 0;
  }
}
