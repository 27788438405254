html {
  box-sizing: border-box;
}

* {
  box-sizing: inherit;

  &::after,
  &::before {
    box-sizing: inherit;
  }
}

::-webkit-scrollbar {
  width: 19px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #c0d45d5e;
}

a {
  &:hover {
    text-decoration: none;
  }
}
body {
  font-family: 'Neue Machina Medium', sans-serif;
  position: relative;
  color: #363642;
  min-height: 100vh;

  &:after {
    content: '';
    background-image: url('../images/bg.png');
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  &.hidden {
    overflow: hidden;
  }
}

button {
  cursor: pointer;
}

h1 {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 64px;
  line-height: 96px;
  color: #363642;
}

.flexAdaptive {
  display: flex;
  flex-direction: row;
}

.flexBetweenCenter {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

div .marginLeftNone {
  margin-left: 0;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.innerPageBlock {
  min-width: 300px;
  width: 100%;
  padding-top: 32px;
  margin-bottom: 82px;
}

.containerDashboard {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

article.disableMargin {
  margin: 0;
}

/*Container for all page*/

.containerMain,
.containerMainCard {
  max-width: 1126px;
  min-width: 300px;
  width: 100%;
  padding: 30px;
  background-color: #ffffffe6;
  border: 1px solid #ededed66;
  backdrop-filter: blur(430px);
  margin: 24px 0 0 auto;

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px;
}

.fullDesk {
  min-height: calc(100vh - 217px);
}

/*header off all page*/

.innerHeaderWrap,
.innerHeaderWrapRow {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}

.innerHeaderPage {
  display: flex;
  flex: 1;
  align-items: baseline;
}

.innerIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
  background-color: #c1d45d;
  height: 42px;
  width: 42px;
  box-shadow: 2px 2px 12px #c1d45dcc;
  border-radius: 12px;
}

.innerHeaderIcon {
  height: 24px;
  width: 24px;
}

.headerTitlePage {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 28px;
  line-height: 42px;
  color: #171717;
}

/*Pagination*/

.pagination {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 30px 14px 12px;
  border-top: 1px solid rgba(239, 239, 239, 0.4);

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin: 0 8px;
    border: 1px solid #dedede66;
    border-radius: 12px;
    color: #171717;
    text-decoration: none;

    &.active {
      border: 1.5px solid #c1d45d;
    }

    &:hover:not(.active) {
      background-color: #ddd;
    }

    &.arrow:first-child {
      border: none;
      background-color: #fafafa;
    }

    &.arrow:last-child {
      border: none;
      background-color: #fafafa;
      transform: rotate(180deg);
    }
  }
}

/*account*/

.accountWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.accountInvestItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: calc(50% - 15px);
  height: 126px;
  margin-bottom: 30px;
  border-radius: 12px;
  padding: 20px 78px 20px 20px;
  background-color: #fff;
  box-shadow: -4px -4px 10px #f4f4f440, 12px 12px 10px #f0f0f03d;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  svg {
    max-width: 150px;
    max-height: 150px;
    width: 100%;
    height: 100%;
  }
}
.accountContent {
  display: flex;
  position: relative;
  flex-direction: column;
  padding-left: 14px;
  flex: 1;
}

.accountContentNotification {
  position: absolute;
  border-radius: 20px;
  background-color: #de350b;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  padding: 0 7px 0 5px;
  min-width: 18px;
  right: 12px;
  top: 7px;
}

.accountContentTitle {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 30px;
  color: #171717;
  padding-right: 40px;
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 20px;
  line-height: 30px;
}

.accountContentText {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: 'Neue Machina Medium', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #8f92a1;
}

.accountIconWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c1d45d33;
  border-radius: 12px;
  min-width: 62px;
  height: 62px;

  img {
    width: 34px;
    height: 34px;
  }
}

.accountContentWrap {
  display: flex;
  padding-right: 34px;
}

.accountArrow {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}

/*tokensList*/

.currentTokensWrap,
.lockedTokensWrap {
  display: flex;
  flex: 1;
  flex-direction: column;
}
.lockedTokensWrap {
  width: 100%;
}

.buttonWrapper {
  display: flex;
  flex-direction: row;
  background-color: #f7f7f7;
  width: 336px;
  height: 42px;
  overflow: hidden;
  border-radius: 33px;
}

.currentFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
}

.tab-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: none;
  background-color: #f7f7f7;
  color: #8f92a1;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;

  &.active {
    background-color: #c1d45d;
    color: #fff;
    border-radius: 33px;
  }
}

.tabsContent {
  display: none;

  &.active {
    display: block;
  }
}

/*dropdown*/

.dropdownItem {
  display: inline-block;
  position: relative;
  width: 187px;
  text-align: left;
  margin-left: 24px;
  height: 44px;
}

.dropHeader {
  position: relative;
  width: 187px;
  height: 44px;
  cursor: pointer;
}

.dropdownArrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  right: 25px;
}

.dropdownItem label {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  color: #8f92a1;
  border-radius: 12px;
  width: 187px;
  height: 44px;
  padding-left: 20px;
  font-weight: 700;
  -webkit-transition: 0.04s ease-in-out;
  transition: 0.04s ease-in-out;
}

.filterWeb {
  display: flex;
}

.filterMob {
  display: none;
}

.list {
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  overflow: hidden;
  margin-top: 10px;
  position: absolute;
  z-index: 1;
  top: 45px;
  left: 0;
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  box-shadow: -4px -4px 10px #f4f4f440, 12px 12px 10px #f0f0f03d;
}

.item {
  width: 100%;
  font-size: 16px;
  line-height: 22px;
  color: #171717;
  font-family: 'Neue Machina Bold', sans-serif;
  padding: 14px;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: #eee;
  }
}

.list.list-active {
  visibility: visible;
  opacity: 2;
  z-index: 10;
}

/*Card*/

.iconCard {
  max-width: 42px;
}

.cardOption {
  background-color: #8f92a119;
  backdrop-filter: blur(240px);
  border-radius: 12px;
  padding: 5px;

  &:nth-child(2) {
    margin: 0 6px;
  }
}

.backLightLive {
  background-color: #00be911a;
  svg {
    stroke: #00be91;
  }

  span {
    background-color: #00be91;
  }

  p {
    color: #00be91;
  }
}

.backLightUpcoming {
  background-color: #f3ba2f1a;
  svg {
    stroke: #f3ba2f;
  }
  span {
    background-color: #f3ba2f;
  }

  p {
    color: #f3ba2f;
  }
}

.backLightEnded {
  background-color: #ff5b4a1a;
  svg {
    stroke: #ff5b4a;
  }
  span {
    background-color: #ff5b4a;
  }

  p {
    color: #ff5b4a;
  }
}

.cardStatusIndicator {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 2px;
}

.lockMargin {
  margin-right: 8px;
}

.cardStatusText {
  font-family: 'Neue Machina Bold', sans-serif;
  padding-left: 6px;
  font-size: 15px;
  line-height: 22px;
  color: #f3ba2f;
}

.innerCardOption {
  margin: 0 0 0 6px;
}

.cardStatus {
  display: flex;
  align-items: center;
  height: 34px;
  justify-content: space-between;
  padding: 0 8px;
  border-radius: 12px;
}

.cardHeader {
  margin-bottom: 14px;
}

.innerOptions {
  display: flex;
  flex-wrap: nowrap;
}

.cardTitle {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #171717;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 303px;
}

.cardSupTitle {
  font-size: 14px;
  line-height: 21px;
}

.innerCardMargin {
  margin: 17px 0;
}

.cardCategory {
  font-size: 14px;
  line-height: 21px;
  color: #171717;
}

.colorTextLightYellow {
  color: #c1d45d;
}

.colorTextGray {
  color: #8f92a1;
}

.cardProgress {
  width: 100%;
  height: 10px;
  border-radius: 40px;
}

progress {
  &::-webkit-progress-value {
    background-color: #c1d45d;
    border-radius: 40px;
  }

  &::-moz-progress-bar {
    border-radius: 40px;
  }

  &::-webkit-progress-bar {
    background-color: #f5f5f5;
    border-radius: 40px;
  }
}

.innerTimer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cardTimer {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 14px;
  line-height: 21px;
  color: #ff5b4a;
  margin-top: 8px;
}

.cardLinkView {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 14px;
  line-height: 21px;
  padding: 17px 19px;
  background-color: #c1d45d19;
  backdrop-filter: blur(240px);
  border-radius: 12px;
}

/*Table Text*/

.innerTableText {
  border: 1px solid #dedede66;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 29px;
}

.tableTextBlock {
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  + .tableTextBlock {
    margin-top: 16px;
  }
}

.tableTextTitle {
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
}
.tableTextDescription {
  font-size: 16px;
  line-height: 22px;
  white-space: wrap;
  text-align: right;
}

.tableLine {
  border: 1px dashed #f2f2f2;
  box-sizing: border-box;
  width: 100%;
  margin: 0 5px;
}

.tableLineLastStep {
  border: 1px dashed #f2f2f2;
  box-sizing: border-box;
  flex: 1 1;
  margin: 0 5px;
}

/*Navigation Form*/

.innerNavigationForm {
  overflow: hidden;
  margin-bottom: 40px;
}

.innerFormState {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 266px;
  max-width: 266px;
  position: relative;
}

.formStateNumber {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 28px;
  line-height: 42px;
  color: #ffffff;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c1d45d;
  box-shadow: 5px 5px 22px #c1d45dcc;
  border-radius: 50%;
  margin-bottom: 14px;
  object-fit: none;
}

.formStateTitle {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #171717;
  margin-bottom: 7px;
}

.formStateSupTitle {
  font-family: 'Neue Machina Regular', sans-serif;
  font-size: 12px;
  line-height: 18px;
  max-width: 209px;
  text-align: center;
  color: #8f92a1;
}

.innerNavigationLine {
  position: absolute;
  display: flex;
  align-items: center;
  max-width: 157px;
  width: 100%;
  right: -80px;
  top: 16px;
}

.initialSpanNavigation {
  width: 16px;
  height: 16px;
  background-color: #c1d45d;
  box-shadow: 5px 5px 22px #c1d45dcc;
  border-radius: 50%;
}

.initialLineNavigation {
  height: 1px;
  background-color: #c1d45d;
  max-width: 125px;
  width: 100%;
  box-sizing: border-box;
}

.secondSpanNavigation {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 1px solid #c1d45d;
  border-radius: 50%;
}

.secondLineNavigation {
  display: inline-block;
  height: 1px;
  background-color: #8f92a166;
  max-width: 125px;
  width: 100%;
  box-sizing: border-box;
}

.secondDisableSpan {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 1px solid #8f92a166;
  border-radius: 50%;
}

.disableNavigationNumber {
  color: #8f92a1;
  background-color: #8f92a166;
  box-shadow: none;
}

/*RadioButton*/

.innerRadioInputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 211px;
  width: 1005px;
}

.containerRadio {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  color: #171717;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border: 1px solid #8f92a166;
  border-radius: 50%;
  transition: all 0.5s;
}

.containerRadio {
  &:hover input ~ .checkmark {
    background-color: #c1d45d80;
    border: 1px solid #8f92a166;
  }

  input:checked ~ .checkmark {
    background-color: #c1d45d;
  }
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.containerRadio {
  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 6px;
    left: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
  }
}

/*Inputs*/

.inputArea.inputArea {
  padding: 15px 20px;
  height: 160px;
}

.formLabel {
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: #8f92a1;
  margin-bottom: 10px;

  span {
    color: red;
  }
}

.textInput {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  background-color: #ffffffe6;
  border: 1px solid #8f92a166;
  backdrop-filter: blur(430px);
  border-radius: 16px;
  line-height: 22px;
  font-size: 16px;
  resize: none;
}

.searchInputWrap {
  max-width: 529px;
  width: 100%;
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
}

.searchInputWrapFull {
  display: flex;
  position: relative;
  align-items: center;
  flex: 1;
}

.searchInput {
  height: 44px;
  padding: 14px 48px;
}

.searchInputIcon {
  background-color: transparent;
  border: none;
  position: absolute;
  left: 12px;
  padding: 0;
  cursor: pointer;
}

.searchInputIconFilter {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 14px;
  padding: 0;
  cursor: pointer;
}

.textInput {
  &::placeholder {
    color: #8f92a1b3;
  }

  &:focus {
    outline: none;
    border: 1px solid #edededff;
  }
}

/*Select*/

.appearanceNone {
  appearance: none;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 14px;
    height: 7px;
    top: 63%;
    right: 20px;
    z-index: -10;
    background-image: url('../images/ArrowSelect.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.backTransparent {
  background-color: transparent;
  backdrop-filter: none;
}

.innerTwoInputs {
  display: flex;
  align-items: flex-start;
}

.marginLaunchInput {
  margin-right: 30px;
}

.innerInputTwice {
  max-width: 518px;
  width: 100%;
}

.mainTitle {
  text-align: center;
  max-width: 723px;
  padding: 0 5px;
  margin: 0 auto;
}

.markerCircle {
  background-image: url(./../images/markerCircle.svg);
  background-position: 50% 50%;
  padding: 20px 10px;
  background-repeat: no-repeat;
  background-size: contain;
}

.titleDescription {
  font-family: 'Neue Machina Regular', sans-serif;
  font-size: 22px;
  line-height: 40px;
  color: #363642;
}

.macImageWrap {
  margin: 66px auto 222px;
  display: flex;
  justify-content: center;
  object-fit: contain;
}

.macImage {
  width: 100%;
  max-width: 1045px;
}

.contentInfo {
  max-width: 759px;
  font-size: 40px;
  line-height: 60px;
  font-family: 'Neue Machina Bold', sans-serif;
  text-align: center;
  margin: 50px auto 0;
}

.investorsWrap {
  display: flex;
  flex-direction: row;
  margin: 50px auto 220px;
  padding: 0 14px;
  justify-content: center;
}

.investorsBlock {
  background-image: url(./../images/gradientCircle.svg);
  background-position: 50% 0;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-size: contain;
  margin: 0 40px;
  width: 400px;
  height: 570px;
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(240px);
  border-radius: 26px;
}

.investorsImg {
  width: 400px;
  height: 400px;
}

.projectBack {
  background-image: url(./../images/project.png);
}

.investorsText {
  font-size: 22px;
  line-height: 33px;
  text-align: center;
}

/*!*marquee*!*/

.marqueeWrap {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  margin: 43px 0 66px;
  height: 46px;
}

.marqueeHead {
  display: flex;
  position: relative;
  z-index: 1;
  width: 176px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  background-color: #d4f2ed;
  font-family: 'Neue Machina Bold', sans-serif;
  color: #00be91;
}

.tradingWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  background-color: #00be9133;
}
.marquee-container {
  .overlay {
    display: none;
  }
}
.marquee-container-default {
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00be910f;
  > div {
    > div {
      display: flex;
      align-items: center;
      margin-right: 100px;
      background-color: transparent;
    }
  }
}

.marqueeItem {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #00be91;
  padding-right: 34px;

  &:hover {
    opacity: 0.5;
  }
}

.marqueeItemNumber {
  padding-right: 8px;
  color: #7ab2a5;
}

/*navbar*/

.menu {
  position: sticky;
  display: flex;
  top: 0;
  z-index: 9;
  height: 86px;
  padding: 0 32px;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
}

.hamburger {
  display: none;
  border: none;
  background-color: #fff;
  cursor: pointer;
}

.closeIcon {
  display: none;
}

.menuHamburger {
  position: fixed;
  display: none;
  overflow: hidden;
  flex-direction: column;
  transform: translateY(-100%);
  transition: transform 0.2s;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: #fff;
  color: white;
  list-style: none;
}

.hamburgerCloseWrap {
  min-height: 58px;
  padding-right: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.hamburgerClose {
  width: 34px;
  height: 34px;
  border-radius: 12px;
  border: 1px solid #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.showMenu {
  display: flex;
  overflow: auto;
  transform: translateY(0);
}

.menuItem {
  display: flex;
  flex: 1;
  height: 56px;
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  align-items: center;
  padding-left: 64px;
  color: #171717;
  cursor: pointer;
  overflow: hidden;
}

.menuItemAccordion {
  display: flex;
  flex: 1;
  height: 56px;
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 14px;
  line-height: 24px;
  position: relative;
  align-items: center;
  padding-left: 64px;
  color: #171717;
  cursor: pointer;
  overflow: hidden;

  &.active,
  &.openAccordion {
    color: #c1c35d;
  }
}

.active .menuItemIcon,
.openAccordion .menuItemIcon {
  background-color: #c1c35d;
  transition: all 0.3s ease-out;
}

.menuItem:hover,
.menuItemAccordion:hover,
.menuAccordion .menuItem:hover,
.menuItemActive,
.menuAccordion .menuItemActive.menuItemActive {
  background-color: #c1d45d1a;
  color: #c1c35d;
  transition: all 0.3s ease-out;
}

.menuItem:hover .menuItemIcon,
.menuItemAccordion:hover .menuItemIcon {
  background-color: #c1c35d;
  transition: all 0.3s ease-out;
}

.menuItem:hover:before,
.menuItemAccordion:hover:before {
  content: '';
  position: absolute;
  left: -3px;
  top: 50%;
  transform: translateY(-50%);
  height: 32px;
  width: 6px;
  border-radius: 3px;
  background-color: #c1d45d;
}

.menuItemActive {
  &.menuItem:before,
  &.menuItemAccordion:before {
    content: '';
    position: absolute;
    left: -3px;
    top: 50%;
    transform: translateY(-50%);
    height: 32px;
    width: 6px;
    border-radius: 3px;
    background-color: #c1d45d;
  }
}

.menuAccordion .menuItem {
  background-color: #e9e9e933;
}

.menuItemAccordion:after {
  content: '';
  background-image: url(./../images/arrowAccord.svg);
  width: 24px;
  height: 24px;
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  right: 22px;
  top: 16px;
  transition: all 0.3s ease-out;
}

.active:after,
.openAccordion:after {
  content: '';
  transform: rotate(90deg);
  filter: invert(85%) sepia(26%) saturate(820%) hue-rotate(19deg) brightness(93%) contrast(92%);
  transition: all 0.3s ease-out;
}

.menuItemIcon {
  position: absolute;
  left: 24px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  display: inline-block;
  -webkit-mask-size: cover;
  mask-size: cover;
  background-color: #8f92a1;
}

.menuItem:hover .menuItemIcon,
.menuItemAccordion:hover .menuItemIcon,
.menuItemActive .menuItemIcon {
  background-color: #c1c35d;
  transition: all 0.3s ease-out;
}

.iconDashboard {
  -webkit-mask: url(../images/menuIcon/menuDashboard.svg) no-repeat 50% 50%;
  mask: url(../images/menuIcon/menuDashboard.svg) no-repeat 50% 50%;
}

.iconLaunchpad {
  -webkit-mask: url(../images/menuIcon/menuLaunchpad.svg) no-repeat 50% 50%;
  mask: url(../images/menuIcon/menuLaunchpad.svg) no-repeat 50% 50%;
}

.iconLaunchIDO {
  -webkit-mask: url(../images/menuIcon/menuLaunchIDO.svg) no-repeat 50% 50%;
  mask: url(../images/menuIcon/menuLaunchIDO.svg) no-repeat 50% 50%;
}

.iconManageIDO {
  -webkit-mask: url(../images/menuIcon/menuManageIDO.svg) no-repeat 50% 50%;
  mask: url(../images/menuIcon/menuManageIDO.svg) no-repeat 50% 50%;
}

.iconTokensList {
  -webkit-mask: url(../images/menuIcon/menuTokensList.svg) no-repeat 50% 50%;
  mask: url(../images/menuIcon/menuTokensList.svg) no-repeat 50% 50%;
}

.iconLocker {
  -webkit-mask: url(../images/menuIcon/menuLocker.svg) no-repeat 50% 50%;
  mask: url(../images/menuIcon/menuLocker.svg) no-repeat 50% 50%;
}

.iconCreateLock {
  -webkit-mask: url(../images/menuIcon/menuCreateLock.svg) no-repeat 50% 50%;
  mask: url(../images/menuIcon/menuCreateLock.svg) no-repeat 50% 50%;
}

.iconLockedTokens {
  -webkit-mask: url(../images/menuIcon/menuLockedTokens.svg) no-repeat 50% 50%;
  mask: url(../images/menuIcon/menuLockedTokens.svg) no-repeat 50% 50%;
}

.iconLockedLPs {
  -webkit-mask: url(../images/menuIcon/menuLockedLPs.svg) no-repeat 50% 50%;
  mask: url(../images/menuIcon/menuLockedLPs.svg) no-repeat 50% 50%;
}

.iconYourAccount {
  -webkit-mask: url(../images/menuIcon/menuYourAccount.svg) no-repeat 50% 50%;
  mask: url(../images/menuIcon/menuYourAccount.svg) no-repeat 50% 50%;
}

.iconDocs {
  -webkit-mask: url(../images/menuIcon/menuDocs.svg) no-repeat 50% 50%;
  mask: url(../images/menuIcon/menuDocs.svg) no-repeat 50% 50%;
}

.accountNotification {
  position: absolute;
  border-radius: 20px;
  background-color: #de350b;
  color: #fff;
  font-size: 10px;
  line-height: 18px;
  padding: 0 7px 0 5px;
  right: 18px;
  top: 19px;
}

.menuAccordion {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}

.openAccordion + .menuAccordion {
  max-height: none;
}

.profileWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 1px solid #efefef;
  border-radius: 12px;
  cursor: pointer;
}

/*subMenu*/

.subMenu {
  min-width: 250px;
  position: sticky;
  top: 86px;
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 86px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid #e6e8ec;
}

.subMenuSticky {
  margin-top: 33px;
  max-width: 250px;
  display: flex;
  flex-direction: column;
}

/*buttons*/

.buttonDefault,
.buttonLearn {
  background-color: #c1d45d;
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 18px;
  line-height: 24px;
  border-radius: 12px;
  outline: none;
  border: none;
  width: 226px;
  height: 50px;
  color: white;
  cursor: pointer;
  margin: 12px;
  transition: all 0.3s ease-out;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.buttonLaunchIDO {
  background-color: #c1d45d;
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 18px;
  line-height: 24px;
  border-radius: 12px;
  outline: none;
  border: none;
  width: 226px;
  height: 50px;
  color: white;
  cursor: pointer;
  margin: 12px;
  transition: all 0.3s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  img {
    margin-right: 8px;
  }
}

.buttonLearn:hover {
  border: 1.5px solid #b7c957;
  color: #b7c957;
  filter: drop-shadow(5px 5px 22px #c1d45dcc);
  transition: all 0.3s ease-out;
}

.buttonDefault {
  &:hover {
    background-color: #b7c957;
    filter: drop-shadow(5px 5px 22px #c1d45dcc);
    transition: all 0.3s ease-out;
  }

  &:focus {
    background-color: #acbd52;
    filter: drop-shadow(5px 5px 22px #c1d45dcc);
    transition: all 0.3s ease-out;
  }
}

.buttonGet,
.buttonGetFull {
  height: 63px;
}

.investorButton {
  width: 100%;
  min-height: 63px;
  margin: 30px 0 0 0;
}

.buttonLearn {
  height: 63px;
  border: 1.5px solid #c1d45d;
  background-color: #fefefe;
  color: #c1d45d;
}

.rightMenuExpended {
  display: flex;
  align-items: center;
}

.tokenButton {
  background-color: #f7f7f7;
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 18px;
  line-height: 24px;
  border-radius: 12px;
  outline: none;
  border: none;
  width: 142px;
  height: 50px;
  color: #8f92a1;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.formBtnDisable {
  font-family: 'Neue Machina Bold', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  margin: 10px auto;
  padding: 16px 66px;
  background-color: #c1d45d;
  backdrop-filter: blur(240px);
  border: none;
  border-radius: 12px;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  transition: all 0.5s;
  cursor: pointer;
  max-width: 207px;
}

.disableArrowBtn {
  opacity: 0.5;
  cursor: auto;
}

.marginTopButton {
  margin-top: 24px;
}

.requestBtn {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  background-color: #3772ff0a;
  border: 1.5px solid #3772ff;
  backdrop-filter: blur(240px);
  border-radius: 12px;
  padding: 17px 27px 15px;
  color: #3772ff;
  transition: all 0.5s;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background-color: #3772ff;
    border: 1.5px solid #3772ff0a;
    backdrop-filter: blur(240px);
  }
}

.requestBtnSelected {
  color: #ffffff !important;
  background-color: #3772ff !important;
  border: 1.5px solid #3772ff0a !important;
  backdrop-filter: blur(240px) !important;
}

.marginBtnDisableRight {
  margin-right: 8px;
}

.marginBtnDisableLeft {
  margin-left: 8px;
}

.marginSubmitArrow {
  margin-right: 24px;
}

.features {
  margin-bottom: 220px;
}

.featuresTitle,
.partnersTitle {
  font-family: 'Neue Machina Bold', sans-serif;
  max-width: 933px;
  text-align: center;
  margin: 0 auto 74px;
  font-size: 40px;
  padding: 0 5px;
  line-height: 60px;
}
.partnersTitle {
  margin: 0 auto 10px;
}

.innerFeatures {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.featuresBlock {
  overflow: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.featuresBlockTitle {
  font-family: 'Neue Machina Bold', sans-serif;
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #363642;
}

.innerProcessSimple {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.processSimpleBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 256px;
  position: relative;
}

.processSimpleImg {
  max-width: 96px;
  width: 100%;
  margin-bottom: 80px;
}

.processSimpleImgPadding {
  margin: 20px 0 96px;
}

.processSimpleTitle {
  font-family: 'Neue Machina Bold', sans-serif;
  margin-bottom: 16px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #363642;
}

.processSimpleText {
  font-family: 'Neue Machina Regular', sans-serif;
  max-width: 210px;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #353945;
}

.connectLine {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 42px;
  left: 75%;
  width: 134px;

  span {
    display: inline-block;
    width: 12px;
    height: 12px;
    background-color: transparent;
    border: 2px solid #b1b5c4;
    border-radius: 50%;
  }

  img {
    width: 100px;
    background-color: transparent;
    margin: 0;
  }
}

/*Be a valuable part of the DeFi economy ("Home")*/

.valuablePart {
  max-width: 1020px;
  margin: 0 auto;
  padding: 0 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.valuablePartTitle {
  font-family: 'Neue Machina Bold', sans-serif;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 480px;
  width: 100%;
  font-size: 40px;
  color: #363642;
  margin-bottom: 40px;
}

.valuablePartSupTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  line-height: 60px;
}

.valuablePartImg {
  max-width: 630px;
  width: 100%;
  margin: 70px 0 50px;
}

.footer {
  background-color: #ffffffe6;
  backdrop-filter: blur(430px);
  padding: 40px 32px;
}

.innerFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 20px;
}

.footerTitleLogo {
  max-width: 185px;
  width: 100%;
}

.footerText {
  font-size: 14px;
  line-height: 25px;
  color: #8f92a166;
}

.underline {
  text-decoration: underline;
}

.innerSocial {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.linkSocial {
  background-color: #8f92a1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  margin: 5px;

  height: 30px;
  width: 30px;
}

.linkSocial:hover {
  opacity: 0.7;
}

.socialImage {
  max-width: 24px;
  width: 100%;
  margin: 0 10px;
}

/*DASHBOARD*/

/*Title to sliders*/

.innerTitleSlider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 14px;
}

.titleSlider {
  display: flex;
  align-items: center;
}

.innerIcon {
  padding: 6px;
  background-color: #5d94d419;
  border-radius: 8px;
}

.titleTextSlider {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 18px;
  line-height: 27px;
  margin: 0 8px;
  color: #171717c0;
}

.innerSliderArrow {
  display: flex;
  align-items: center;
}

.arrowImg {
  padding: 3px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    opacity: 0.5;
  }
}

.leftArrowMargin {
  margin-right: 14px;
  transform: rotate(180deg);
}

/*Title to sliders*/

.innerDoubleSlider {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.sliderItemShowOne {
  max-width: 300px;
  width: 100%;
  margin-right: 20px;
  overflow-x: hidden;
}

.innerShowSliders {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sliderItem {
  min-width: 300px;
  height: 250px;
  margin: 0 16px;
  background-color: #e5def0;
  box-shadow: -4px -4px 10px #f4f4f440, 12px 12px 10px #f0f0f03d;
  border: none;
  padding: 24px 24px 0;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
}

.sliderItemText {
  font-family: 'Neue Machina Bold', sans-serif;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  color: #ffffff;
}

.sliderItemTitle {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 28px;
  line-height: 42px;
}

.sliderItemSupText {
  font-size: 16px;
  line-height: 24px;
}

.sliderItemBrand {
  font-family: 'Neue Machina Ultrabold', sans-serif;
  font-size: 28px;
  line-height: 42px;
  color: #9859fb;
}

.sliderItemImage {
  position: absolute;
  bottom: 0;
  left: 0;
}

.sliderItem {
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.sliderItemShowTwo {
  max-width: 705px;
  width: 100%;
  overflow-x: hidden;
}

/*second shelf*/

.innerBlocks {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 40px;
}

.investments {
  width: 100%;
  max-width: 660px;
}

.innerInvestments {
  padding: 24px;
  min-height: 428px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #fff;
  box-shadow: -4px -4px 10px #f4f4f440, 12px 12px 10px #f0f0f03d;
  border-radius: 12px;
}

.investmentsHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.investmentsTitle {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 22px;
  line-height: 33px;
  color: #171717;
  margin-bottom: 20px;
}

.investmentsBalance {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #8f92a1;
}

.investmentsPrice {
  font-family: 'Neue Machina Bold', sans-serif;
  display: block;
  font-size: 24px;
  line-height: 36px;
  color: #171717;
  margin-top: 6px;
}

/*Coin Up custom dashboard*/

.selectInvestments {
  font-family: 'Neue Machina Bold', sans-serif;
  appearance: none;
  font-size: 16px;
  line-height: 24px;
  color: #00be91;
  max-width: 237px;
  background-color: #00be9133;
  border-radius: 12px;
  border: none;
  padding: 10px 30px 10px 12px;
  position: relative;
  z-index: 1;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 6px;
    background-image: url('../images/iconSelect.svg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    right: 10px;
    top: 20px;
  }
}

.textNullRating {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #8f92a1;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-image: url('../images/backTextFont.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.innerCartDiagram {
  height: 215px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.canvas {
  width: 100% !important;
}

.yourStarter {
  /*max-width: 376px;*/
  width: 100%;
}

.yourStarter .cardDashboard {
  min-width: 376px;
}

.innerThirdSlider {
  display: flex;
  /*max-width: 376px;*/
  overflow-x: scroll;
  scroll-behavior: smooth;
  box-shadow: -4px -4px 10px #f4f4f440, 12px 12px 10px #f0f0f03d;
  border-radius: 12px;

  &::-webkit-scrollbar {
    display: none;
  }
}

.cardDashboard {
  /*min-width: 376px;*/
  height: 428px;
  padding: 28px;
  background-color: #fff;
  box-shadow: -4px -4px 10px #f4f4f440, 12px 12px 10px #f0f0f03d;
  border-radius: 12px;
  margin: 0 10px;
  transition: 0.3s;
  &.withoutHeight {
    height: auto;
  }
  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.CardShadow {
  box-shadow: -4px -4px 22px rgba(193, 212, 93, 0.25), 12px 12px 22px rgba(193, 212, 93, 0.24);
}

/*DASHBOARD*/

/* CREATE LOCK*/

.innerCreateLockForm {
  margin-bottom: 16px;
}

.dateInput {
  color: #8f92a1b3;
}

.addressToken {
  word-break: break-word;
}

.createLockFormSupText {
  background-color: #f3ba2f19;
  backdrop-filter: blur(240px);
  border-radius: 12px;
  padding: 20px;
  font-size: 14px;
  line-height: 22px;
  color: #171717;
  width: 100%;

  div {
    max-width: 710px;
  }
}

.innerCopyText {
  font-family: 'Neue Machina Bold', sans-serif;
  display: inline-block;
}

.createLockWillPay {
  max-width: 207px;
  text-align: center;
  padding: 12px 22px 10px;
  font-size: 16px;
  line-height: 22px;
  color: #c1c35d;
  margin: 20px auto;
  background-color: #c1d45d33;
  backdrop-filter: blur(430px);
  border-radius: 16px;

  span {
    font-family: 'Neue Machina Bold', sans-serif;
  }
}

.copyImage {
  cursor: pointer;
  margin-bottom: -4px;
}

/*LAUNCH IDO*/

.blockBackgroundLightYellow {
  padding: 20px;
  background-color: #f3ba2f1a;
  backdrop-filter: blur(240px);
  border-radius: 12px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
}

.blockBackgroundLightRed {
  color: red !important;
}

.currentFeesMargin {
  margin: 16px 0 24px;
}

.dangerCircle {
  margin-right: 8px;
}

/*Second Launch IDO*/

.innerLaunchInput {
  margin-bottom: 24px;
  position: relative;
}

.supTextInputText {
  margin-top: 8px;
  font-size: 12px;
  line-height: 22px;
  color: #457cff;
}

.iconI {
  margin-left: 6px;
}

.textCreateIdo {
  font-size: 16px;
  line-height: 22px;
  color: #8f92a1;
  max-width: 437px;
  text-align: center;
  padding: 13px 9px 9px;
  background-color: #f8f8f8;
  backdrop-filter: blur(430px);
  border-radius: 16px;
  margin: 24px auto;
}

.maxBtnArrowWidth {
  max-width: 438px;
  margin: 0 auto;
}

/*MANAGE IDO*/

.innerManage {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
}

.innerManageCard {
  width: 339px;
  margin: 0 6px 25px;

  &:first-child {
    margin-left: 6px;
  }

  &:last-child {
    margin-right: 6px;
  }
}

.fontBold {
  font-family: 'Neue Machina Bold', sans-serif;
}

/*Previous Investments*/

.innerStatusMoney {
  font-family: 'Neue Machina Bold', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  padding-top: 8px;
  padding-bottom: 6px;
  border-radius: 8px;
  margin: 9px 0 18px;
}

.statusMoneyImg {
  margin-left: 6px;
  width: 10px;
  height: 4px;
}

.statusMoneyGood {
  background-color: #00be9133;

  svg {
    fill: #00be91;
  }

  .statusMoneyText {
    color: #00be91;
  }
}

.statusMoneyBad {
  background-color: #ff5b4a33;

  svg {
    fill: #ff5b4a;
  }

  .statusMoneyText {
    color: #ff5b4a;
  }
}

.innerBlocksProfile {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.bigBlockProfile {
  max-width: 400px;
  width: 100%;
  margin: 0 20px 20px 0;
}

.headerBlockProfile {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  padding: 21px 20px 12px;
  background-color: #c1d45d;
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.innerBigBlockProfile {
  padding: 20px;
  background-color: #fcfcfc;
  border: 1px solid #efefef66;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.riskLevel {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #171717;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.redCube {
  margin-left: 10px;
  height: 28px;
  width: 28px;
  background-color: #ff5b4a;
  border-radius: 6px;
}

.riskLevelSupText {
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 16px;
}

.secondBlockProfile {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-bottom: 20px;
  background-color: #fcfcfc;
  border: 1px solid #efefef66;
  border-radius: 16px;
}

.blockHeader {
  max-width: 331px;
  width: 100%;
}

.blockHeaderBorder {
  border-top-right-radius: 0;
}

.innerSmallBlockProfile {
  padding: 10px;
  background-color: #fafafa;
  border-radius: 16px 0 0 16px;
}

.blockProfileList {
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  padding: 10px 10px 6px;
  width: 100%;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #c1d45d1a;
    border-radius: 12px;
    color: #c1c35d;
  }
}

.blockSomeText {
  font-size: 16px;
  line-height: 24px;
  color: #8f92a1;
  padding-left: 20px;
  max-width: 275px;
}

.secondClockHeader {
  height: 100%;
}

.tableProfile {
  max-width: 890px;
  margin: 20px 0;
}

.colorTextBlue {
  color: #3772ff;
}

/*Profile*/

.innerProfile {
  display: flex;
  align-items: flex-start;
}

.innerProfileInfo {
  margin-right: 30px;
  max-width: 556px;
  width: 100%;
}

.wrapperInfo {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.profileLogo {
  max-width: 72px;
}

.innerProfileSocialName {
  margin: 0 25px 0 20px;
}

.profileName {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 28px;
  line-height: 42px;
  color: #171717;
  text-align: center;
}

.innerProfileStatus {
  display: flex;
  flex-wrap: nowrap;
  cursor: pointer;
  .profileIconStatus {
    width: 48px;
    height: 48px;
  }
}
.error {
  color: red;
  font-size: 13px;
}

.profileIconStatus {
  margin: 0 3px;
}

.backSaleLive {
  background-color: #00be911a;
  backdrop-filter: blur(240px);

  span {
    background-color: #00be91;
  }

  .cardStatusText {
    color: #00be91;
  }
}

.profileText {
  font-family: 'Neue Machina Regular', sans-serif;
  font-size: 16px;
  line-height: 29px;
  color: #8f92a1;
  margin-top: 20px;
}

.innerSecondProfile {
  background-color: #fcfcfc;
  border: 1px solid #efefef66;
  border-radius: 16px;
  padding: 20px;
  margin-bottom: 20px;
}

.secondBlockProfileTitle {
  font-size: 16px;
  line-height: 24px;
  color: #171717;
  margin-bottom: 16px;
  text-align: center;
  height: 100%;
}

.profileTimer {
  font-family: 'Neue Machina Bold', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 36px;
  color: #ff5b4a;

  span {
    padding: 5px 4px;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    margin: 0 6px;
  }
}

.innerSecondAllBlock {
  max-width: 480px;
  width: 100%;
}

.innerProfileProgress {
  margin: 24px 0 16px;

  progress {
    height: 20px;
  }
}

.formProfileMax {
  position: relative;
}

.formProfileMaxLabel {
  font-size: 16px;
  line-height: 22px;
  color: #171717;
}

.formProfileMaxInput {
  margin: 10px 0 24px;
  padding-right: 60px;
}

.maxOnInput {
  position: absolute;
}

.formProfileMaxBuy {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 17px 20px 15px;
  background-color: #c1d45d;
  backdrop-filter: blur(240px);
  border-radius: 12px;
  color: #ffffff;
  border: none;
}

.maxOnInput {
  font-family: 'Neue Machina Bold', sans-serif;
  right: 10px;
  top: 27%;
  font-size: 16px;
  line-height: 22px;
  color: #c1d45d;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.innerBlockMediaSocial {
  display: flex;
}

.displayOnTablet {
  display: none;
  margin: 11px 0 16px;
}

.profilePoolEmbed {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  padding: 9px 20px 6px;
  text-align: center;
  background-color: #ff5b4a1a;
  border-radius: 10px;
  margin-top: 12px;
}

.colorRed {
  color: #ff5b4a;
}

.colorTextPink {
  color: #00be91;
}

.profileCurrentPrice {
  font-size: 20px;
  line-height: 30px;
  color: #171717;
}

.profileColumnTitle {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 10px;
}

.profileColumnSupTitleText {
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 14px;
  line-height: 21px;
}

.marketCap {
  padding: 5px 8px 1px;
  background-color: #f5f5f5;
  border-radius: 10px;
  max-width: fit-content;
}

/*Registration page*/

.registerWrap {
  display: flex;
  min-height: calc(100vh - 86px);
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.innerRegisterPage {
  padding: 0 14px;
}

.marginMarqueRegistration {
  margin: 32px 0 50px;
}

.yourWallet {
  border: 1px solid #ededed66;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(430px);
  border-radius: 16px;
  max-width: 542px;
  width: 100%;
  padding: 30px;
}

.yourWalletPage {
  margin: 0 auto 247px;
}

.yourWalletTitle {
  font-family: 'Neue Machina Bold';
  font-size: 28px;
  line-height: 42px;
  text-align: center;
  color: #171717;
}

.yourWalletSupTitle {
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #8f92a1;
  margin: 10px 0 20px;
}

.innerAllWallet {
  max-height: 358px;
  overflow-y: hidden;
}

.wallet {
  background: #fafafa;
  border-radius: 12px;
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 82px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}

.walletImg {
  max-width: 46px;
  margin-right: 20px;
}

.walletText {
  font-family: 'Neue Machina Bold';
  font-size: 24px;
  line-height: 36px;
  color: #171717;
}

.showMoreOptionBtn {
  font-family: 'Neue Machina Bold';
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-top: 30px;
  color: #c1d45d;
  cursor: pointer;
}

.overflowScroll {
  overflow-y: scroll;
}

.colorTextPink {
  color: #00be91;
}

.chartMain {
  display: flex;
  flex-direction: column;
  margin: 24px 0 30px;
  border-radius: 16px;
  padding: 20px;
  background-color: #fcfcfc;
  border: 1px solid rgba(239, 239, 239, 0.4);
}

.chartHeader {
  display: flex;
  flex: 1;
  font-family: 'Neue Machina Bold', sans-serif;
  height: 66px;
  font-size: 18px;
  line-height: 22px;
  border-bottom: 1px solid rgba(239, 239, 239, 0.4);
  padding-bottom: 24px;
}

.chartBox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 60px auto;
  height: 342px;
  width: 342px;
  position: relative;
}

.chartTitle {
  position: absolute;
  font-family: 'Neue Machina Bold', sans-serif;
  font-size: 26px;
}

.chartValueWrap {
  position: absolute;
  top: 0;
  right: -276px;
  width: 200px;
}

.chartValueWrapMob {
  display: none;
}

.chartColorValue {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background-color: forestgreen;
  border-radius: 12px;
}

.chartValueItem {
  display: flex;
  margin-bottom: 30px;
  align-items: center;
  font-size: 20px;
  line-height: 22px;
  font-family: 'Neue Machina Bold', sans-serif;
}

.backgroundColorYellow {
  background-color: #e5c626;
}

.backgroundColorBlue {
  background-color: #266de5;
}

.modal {
  display: none;

  /* Hidden by default */
  position: fixed;

  /* Stay in place */
  z-index: 99;

  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;

  /* Full width */
  height: 100%;

  /* Full height */
  overflow: auto;

  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);

  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);

  /* Black w/ opacity */
  -webkit-animation-name: fadeIn;

  /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
  padding: 0 14px;
}

/* Modal Content */

.modal-content {
  position: sticky;
  margin: 0 auto;
  top: calc(100vh - 80%);
  background-color: #fefefe;
  width: 100%;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    top: calc(-100vh + 80%);
    opacity: 0;
  }

  to {
    top: 400px;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    top: calc(-100vh + 80%);
    opacity: 0;
  }

  to {
    top: calc(100vh - 80%);
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.paddingToCart {
  padding: 18px;
}

.backOrangeKey {
  background-color: #f3ba2f;
}

.numberType {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

.heightTabletFix {
  height: auto;
}

@media (max-width: 1300px) {
  .innerBlocksProfile {
    flex-direction: column;
    align-items: center;
  }

  .secondBlockProfile {
    justify-content: center;
    max-width: fit-content;
  }

  .bigBlockProfile {
    margin-right: 0;
  }

  .innerProfile {
    flex-direction: column;
  }

  .innerProfileInfo {
    margin-right: 0;
    max-width: initial;
  }

  .innerSecondProfile {
    width: 100%;
  }

  .innerSecondAllBlock {
    max-width: initial;
    width: 100%;
    margin-top: 14px;
  }

  .innerProfileSocialName {
    margin: 0 10px;
  }

  .chartBox {
    width: 270px;
    height: 270px;
    margin: 60px 20px 60px;
  }
}

@media (max-width: 991px) {
  .heightTabletFix {
    height: 34px;
  }

  .displayOnTablet {
    display: flex;
    align-items: center;
  }

  .noneWidthTablet {
    display: none;
  }

  .containerMain {
    margin: 20px 14px 0;
    padding: 16px 14px 18px;
    width: auto;
  }

  .containerMainCard {
    margin: 20px 14px 0;
    padding: 16px 10px 18px;
    width: auto;
    transition: 0.3s;

    .innerHeaderWrap,
    .innerTitleSlider,
    .innerHeaderWrapRow,
    .currentFilter,
    .buttonWrapper {
      margin-left: 14px;
      margin-right: 14px;
    }
  }

  .fullDesk {
    height: auto;
  }

  .innerShowSliders {
    margin: 0 14px;
  }

  .subMenu,
  .menuLeftSide .logoIcon {
    display: none;
  }

  .menu {
    height: 55px;
    padding: 0 14px;

    .menuLogo {
      width: 123.2px;
      height: 20px;
    }

    .tokenButton {
      display: none;
    }

    .buttonDefault {
      font-size: 14px;
      line-height: 24px;
      width: auto;
      height: 34px;
      margin: 0;
      padding: 0 10px;
    }
  }

  .buttonLaunchIDO {
    width: 30px;
    height: 30px;

    span {
      display: none;
    }

    img {
      width: 15px;
      height: 15px;
      margin-right: 0;
    }
  }

  .menuItem,
  .menuItemAccordion {
    font-size: 16px;
    line-height: 24px;
    padding-left: 54px;
  }

  .menuItemIcon {
    left: 14px;
  }

  .profileWrap {
    width: 34px;
    height: 34px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .hamburger {
    display: block;
  }

  .mainTitle h1 {
    font-size: 28px;
    line-height: 42px;
  }

  .flexAdaptive {
    flex-direction: column;
  }

  .headerTitlePage {
    font-size: 22px;
    line-height: 33px;
  }

  .innerIconWrap {
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }

  .innerHeaderIcon {
    height: 17px;
    width: 17px;
  }

  .buttonGet,
  .buttonLearn {
    height: 47px;
    width: 154px;
    margin: 7px;
  }

  .buttonGetFull {
    height: 47px;
    width: 299px;
  }

  .contentInfo {
    max-width: 759px;
    font-size: 28px;
    line-height: 42px;
    padding: 0 14px;
    margin: 0 auto 0;
  }

  .macImageWrap {
    margin: 33px auto 94px;
  }

  .investorsWrap {
    margin: 34px auto 70px;
    flex-direction: column;
  }

  .investorsBlock {
    flex: 1;
    width: auto;
    height: 442px;
    margin: 0 0 24px;
  }

  .investorsImg {
    width: 279px;
    height: 279px;
  }

  .investorsText {
    font-size: 18px;
    line-height: 24px;
  }

  .investorButton {
    width: 100%;
    height: 50px;
    margin: 18px 0 0 0;
    font-size: 16px;
  }

  .titleDescription {
    font-size: 16px;
    line-height: 28px;
    margin: 0 14px 17px;
  }

  .marqueeWrap {
    margin: 15px 0 50px;
    height: 36px;
  }

  .tradingWrap {
    width: 20px;
    height: 20px;
    margin-right: 6px;

    img {
      width: 13px;
      height: 13px;
    }
  }

  .featuresTitle,
  .partnersTitle {
    font-weight: bold;
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 34px;
  }
  .partnersTitle {
    margin-bottom: 10px;
  }

  .featuresBlockTitle {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 20px;
  }

  .features {
    margin-bottom: 94px;
  }

  .featuresBlock:first-child {
    margin-bottom: 34px;
  }

  .valuablePartTitle {
    font-size: 28px;
    line-height: 42px;
    max-width: 347px;
  }

  .valuablePartImg {
    margin-bottom: 40px;
  }

  .socialImage {
    margin: 0 8px;
    max-width: 20px;
  }

  .footer {
    padding: 23px 14px 20px;
  }

  /*  Sliders*/

  .sliderItemShowOne {
    max-width: initial;
    margin-right: 0;
  }

  .innerDoubleSlider {
    flex-wrap: wrap;
  }

  .sliderItemShowTwo {
    max-width: initial;
    margin-top: 20px;
  }

  .innerBlocks {
    flex-wrap: wrap;
  }

  .investments {
    max-width: initial;
    margin-bottom: 24px;
  }

  .yourStarter,
  .innerThirdSlider {
    max-width: initial;
  }

  .cardDashboard {
    /*min-width: 344px;*/
    padding: 18px 21px;
  }

  .investmentsTitle {
    font-size: 14px;
    line-height: 21px;
  }

  .selectInvestments {
    font-size: 12px;
    line-height: 18px;
    padding: 4px 14px 4px 4px;
    border-radius: 6px;

    &::after {
      width: 8px;
      height: 4px;
      right: 4px;
      top: 11px;
    }
  }

  .innerInvestments {
    min-height: 367px;
    padding: 11px 14px;
  }

  .textNullRating {
    height: 210px;
  }

  .titleTextSlider {
    font-size: 16px;
    line-height: 24px;
  }

  .formLabel {
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 6px;
  }

  .textInput {
    height: 44px;
    padding: 0 14px;
    font-size: 12px;
    line-height: 22px;
  }

  .innerHeaderWrap {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;
  }

  .innerHeaderWrapRow {
    flex-direction: row;
  }

  .searchInputWrap {
    margin-top: 24px;
    max-width: none;
  }

  .searchInput {
    padding: 14px 44px;
    height: 44px;
    font-size: 12px;
    line-height: 22px;
  }

  .searchInputIcon img {
    width: 20px;
    height: 20px;
  }

  /*pagination*/

  .pagination {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-top: 2px;
    padding: 18px 0 0;

    a {
      width: 34px;
      height: 34px;
    }
  }

  .createLockFormSupText {
    font-size: 12px;
    line-height: 22px;
    padding: 6px;
  }

  .createLockWillPay {
    font-size: 14px;
    line-height: 22px;
    padding: 8px 12px 6px;
  }

  .formBtnDisable {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    justify-content: center;
    padding-top: 11px;
    padding-bottom: 12px;
    max-width: initial;
  }

  .copyImage {
    max-width: 16px;
  }

  .innerCopyText {
    font-size: 10px;
  }

  .headerBlockProfile {
    padding: 12px 14px 10px;
  }

  .blockBackgroundLightYellow {
    font-size: 12px;
    line-height: 22px;
    padding: 6px;
    margin: 14px 0;
    display: block;
  }

  .dangerCircle {
    display: block;
  }

  .innerTableText {
    padding: 16px;
  }

  .tableTextTitle {
    font-size: 14px;
    line-height: 22px;
  }
  .tableTextDescription {
    font-size: 14px;
    line-height: 22px;
    white-space: wrap;
    word-break: break-word;
    text-align: left;
  }

  .supTextInputText {
    font-size: 10px;
    line-height: 22px;
  }

  .containerRadio {
    font-size: 14px;
    line-height: 22px;
  }

  .innerTwoInputs {
    flex-direction: column;
  }

  .innerInputTwice {
    max-width: initial;
  }

  .innerLaunchInput {
    margin-bottom: 14px;
  }

  .requestBtn {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    padding: 11px 27px 9px;

    &:first-child {
      margin-bottom: 14px;
    }
  }

  .textCreateIdo {
    max-width: initial;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    padding: 8px 6px 6px;
    margin: 20px auto;
  }

  .maxBtnArrowWidth {
    max-width: initial;
  }

  .formBtnDisable:nth-child(2) {
    margin-bottom: 14px;
    order: -1;
  }

  .secondStep {
    margin-left: -235px;
  }

  .thirdStep {
    margin-left: -495px;
  }

  .marginSubmitArrow {
    margin-right: 0;
  }

  .fourthStep {
    margin-left: -770px;
  }

  .innerManageCard {
    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }

    margin: 0 0 16px;
    width: 100%;
  }

  /*account*/

  .accountInvestItem {
    width: 100%;
    height: 105px;
    margin-bottom: 15px;
    padding: 16px 81px 16px 14px;
  }

  .accountIconWrap {
    min-width: 30px;
    height: 30px;

    img {
      width: 16px;
      height: 16px;
    }
  }

  .accountArrow {
    height: 20px;
    width: 20px;
  }

  .accountContentTitle {
    font-size: 14px;
    line-height: 21px;
    height: 21px;
  }

  .accountContentNotification {
    top: 1px;
    min-width: 16px;
    line-height: 16px;
    padding: 0 5px 0 4px;
  }

  .accountContentText {
    font-size: 14px;
    line-height: 21px;
  }

  .innerBigBlockProfile {
    padding: 14px 14px 16px;
  }

  .riskLevelSupText:last-child {
    margin-bottom: 0;
  }

  /*tokensList*/

  .searchInputWrapFull {
    margin-bottom: 14px;
    width: 100%;
  }

  .currentFilter {
    margin: 0 0 24px;
    flex-direction: column;
  }

  .dropdownItem label {
    height: 38px;
    padding-left: 14px;
  }

  .dropdownArrow {
    right: 14px;
  }

  .dropdownItem {
    display: flex;
    width: calc(50% - 5.5px);
    margin-left: 0;
    height: 38px;
  }

  .buttonWrapper {
    width: auto;
    margin-bottom: 20px;
  }

  .dropdownItem label {
    width: 100%;
  }

  .filterWeb {
    display: none;
  }

  .filterMob {
    display: flex;
  }

  .bigBlockProfile {
    max-width: initial;
    margin-bottom: 16px;
  }

  .secondBlockProfile {
    flex-direction: column;
    margin-bottom: 16px;
  }

  .blockSomeText {
    max-width: initial;
    padding: 0 14px 16px;
    font-size: 14px;
    line-height: 21px;
  }

  .riskLevel {
    font-size: 14px;
  }

  .redCube {
    width: 20px;
    height: 20px;
  }

  .riskLevelSupText {
    font-size: 14px;
    line-height: 21px;
  }

  .blockProfileList {
    padding: 10px 4px 6px;
    font-size: 14px;
    line-height: 21px;
  }

  .blockHeader {
    max-width: initial;
  }

  .blockHeaderBorder {
    border-top-right-radius: 16px;
  }

  .displayTableBlock {
    display: block;
    padding: 14px 0;
  }

  .profileLogo {
    max-width: 30px;
  }

  .profileName {
    font-size: 22px;
  }

  .profileSocialIcon {
    max-width: 24px;
    margin: 0 6px;
  }

  .profileIconStatus {
    max-width: 34px;
    margin: 0 6px;
  }

  .profileText {
    font-size: 14px;
    margin-top: 16px;
  }

  .formProfileMaxLabel {
    font-size: 14px;
  }

  .yourWalletTitle {
    font-size: 22px;
    line-height: 33px;
  }

  .yourWalletSupTitle {
    font-size: 14px;
    line-height: 21px;
  }

  .walletText {
    font-size: 16px;
    line-height: 24px;
  }

  .walletImg {
    max-width: 30px;
  }

  .showMoreOptionBtn {
    font-size: 16px;
  }

  .wrapIntoMobile {
    flex-wrap: wrap;
  }

  .yourWalletPage {
    margin: 0 auto 148px;
  }

  .yourWallet {
    padding: 20px 14px;
  }

  .wallet {
    height: 62px;
    margin-bottom: 14px;
    padding: 0 16px;
  }

  .marginMarqueRegistration {
    margin-bottom: 20px;
  }

  .innerAllWallet {
    max-height: 293px;
  }

  .showMoreOptionBtn {
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
  }

  .inputArea {
    padding: 12px 14px;
    height: 160px;
  }

  .sliderItem {
    min-width: 315px;
  }

  .chartBox {
    margin: 36px auto 6px;
  }

  .chartValueWrap {
    position: absolute;
    top: 0;
    right: -276px;
    width: 200px;
    display: none;
  }

  .chartValueWrapMob {
    display: block;
  }

  .chartValueItem {
    width: 100%;
    margin-bottom: 0;
    margin-top: 14px;
  }

  .chartColorValue {
    width: 24px;
    height: 24px;
    border-radius: 6px;
  }

  .backgroundColorBlue {
    font-size: 14px;
    line-height: 22px;
  }

  .chartMain {
    padding: 14px;
  }

  .chartHeader {
    padding: 0 14px 14px;
    margin: 0 -14px;
  }
}

@media (max-width: 414px) {
  .styleWrap {
    flex-wrap: wrap;
  }

  .tableLineHidden {
    opacity: 0;
  }
}

@media (max-width: 374px) {
  .buttonGet,
  .buttonLearn {
    width: 130px;
  }

  .featuresTitle,
  .partnersTitle {
    padding: 0 10px;
  }

  .mainTitle h1,
  .featuresTitle,
  .valuablePartTitle,
  .contentInfo,
  .partnersTitle {
    font-size: 22px;
    line-height: 34px;
  }

  .requestBtn {
    font-size: 12px;
  }

  .cardDashboard {
    /*min-width: 300px;*/
  }

  .addressToken {
    min-width: 267px;
  }

  .dropdownItem label {
    font-size: 14px;
  }

  .tab-button {
    font-size: 14px;
    line-height: 18px;
  }

  .containerMainCard,
  .containerMain {
    margin: 20px 8px 0;
  }

  .sliderItem {
    min-width: 272px;
  }
}

#display {
  background: hsla(210, 100%, 50%, 1);
  height: 60px;
  width: 500px;
  overflow: hidden;
  position: relative;
}

#text {
  background: hsla(90, 100%, 50%, 0.5);
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  left: 10px;
  margin-right: 10px;
  top: 10px;
}
