.subMenuFooter {
  padding: 24px;
  @media screen and (max-width: 991px) {
    padding: 15px;
    margin-top: auto;
    width: fit-content;
  }

  .subMenuFooterInner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 8px 12px;
    padding-left: 0;
    background-color: #fafafa;
    border-radius: 16px;
    margin-bottom: 16px;
  }

  .footerText,
  .footerTextUnderline,
  .footerTextUnderlineAcademy {
    font-size: 14px;
    line-height: 25px;
    color: #8f92a166;
  }

  .footerTextUnderline,
  .footerTextUnderlineAcademy {
    text-decoration: underline;
  }
  .wrapperLinks {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > .footerTextUnderlineAcademy {
      margin-right: 10px;
    }
  }
}
